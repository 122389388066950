import ec from '@evag/ec-html';

const beforeSubmit = form => {
  let inputUF = form.querySelector('[name="uf"]:not([type="hidden"])');
  let inputCity = form.querySelector('[name="city"]:not([type="hidden"])');
  if (inputUF || inputCity) {
    return true;
  }

  const data = new FormData(form);
  const district = data.get('district');
  if (!district) {
    return true;
  }

  return true;
};

const jumpCallback = (form) => {
  const multiforms = form.closest(`[data-evag="multiforms"]`);
  if (!multiforms) {
    return 1;
  }

  const forms = multiforms.querySelectorAll('[data-evag="form"]');

  let cur = -1;
  for (let i = 0; i < forms.length; i++) {
    if (form === forms[i]) {
      cur = i;
    }
  }

  if (cur === -1) {
    return 1;
  }

  const next = forms[cur + 1];
  if (!next) {
    return 1;
  }

  if (!next.dataset.redirect) {
    return 1;
  }

  location.href = next.dataset.redirect;
  return 1;
};

const listAllEventListeners = (element) => {
  const allElements = Array.prototype.slice.call(element.querySelectorAll('*'));
  allElements.push(element);

  const types = [];

  for (let ev in window) {
    if (/^on/.test(ev)) types[types.length] = ev;
  }

  types.push("submit");

  let elements = [];
  for (let i = 0; i < allElements.length; i++) {
    const currentElement = allElements[i];
    for (let j = 0; j < types.length; j++) {
      if (typeof currentElement[types[j]] === 'function') {
        elements.push({
          "node": currentElement,
          "type": types[j],
          "func": currentElement[types[j]].toString(),
        });
      }
    }
  }

  return elements.sort(function(a,b) {
    return a.type.localeCompare(b.type);
  });
}

const setup = () => {
  document.querySelectorAll('[data-evag="form"]').forEach(form => {
    console.table(listAllEventListeners(form));
    ec.forms.setup(form, {beforeSubmit, jumpCallback});
    console.table(listAllEventListeners(form));
  });
};

export {setup};
